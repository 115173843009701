<!--
File: Condition.vue
Description: show list of Condition data (IRI, rutting, etc.) entered in the Data100 table.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RegionsDropdown :label="$t('label.select_region')" v-model="selectedRegion" :initial_value="selectedRegion"
            @input='reloadData' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RoadsDropdown :label="$t('label.select_road')" v-model="selectedRoad" :initial_value="selectedRoad"
            :region_id="selectedRegion" :disabled='!selectedRegion' @input='reloadData' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-25">
          <SectionsDropdown :label="$t('label.select_section')" v-model="selectedSection" :region_id="selectedRegion"
            :road_id="selectedRoad" :initial_value="selectedSection" :disabled='!selectedRegion'
            @input='reloadData' />
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="addCondition"> {{ $t('buttons.add') }} </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" class="md-progress-spinner" :md-diameter="70"
        md-mode="indeterminate" />
      <md-table class="paginated-table table-striped table-hover" :value="tableData" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')"
          :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell :md-label="$t('road_network.start_distance')" md-sort-by="start_distance" md-numeric>
            {{ item.start_distance }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.end_distance')" md-sort-by="end_distance" md-numeric>
            {{ item.end_distance }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.iri')" md-sort-by="iri" md-numeric>
            {{ item.iri | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.rut_left')" md-sort-by="rut_left" md-numeric>
            {{ item.rut_left | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.rut_right')" md-sort-by="rut_right" md-numeric>
            {{ item.rut_right | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.cracking_m2')" md-sort-by="cracking_m2" md-numeric>
            {{ item.cracking_m2 | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.potholes_no')" md-sort-by="potholes_no" md-numeric>
            {{ item.potholes_no | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.repairs_m2')" md-sort-by="repairs_m2" md-numeric>
            {{ item.repairs_m2 | numFormat }}
          </md-table-cell>
          <md-table-cell :md-label="$t('tables.actions')">
            <div>
              <md-button class="md-just-icon md-default md-simple" :title="$t('buttons.edit')"
                @click.native="editCondition(item.data_id)">
                <md-icon>edit</md-icon>
              </md-button>
              <md-button v-if="amIAdmin" class="md-just-icon md-danger md-simple" :title="$t('buttons.delete')"
                @click.stop.prevent="handleDelete(item)">
                <md-icon>delete</md-icon>
              </md-button>
            </div>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </md-card-content>
    <md-card-actions md-alignment="space-between">
      <div class>
        <p class="card-category">
          {{
            $t('label.showing_from_to_of_entries', {
              from: to > 0 ? from + 1 : 0,
              to: to,
              total
            })
          }}
        </p>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-15">
        <md-field>
          <label for="pages">{{ $t('label.per_page') }}</label>
          <md-select v-model="pagination.perPage" name="pages">
            <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
              {{ item }}
            </md-option>
          </md-select>
        </md-field>
      </div>
      <pagination class="pagination-no-border pagination-success" v-model="pagination.currentPage"
        :per-page="pagination.perPage" :total="total"></pagination>
    </md-card-actions>
  </md-card>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { Pagination } from '@/components'
import Swal from 'sweetalert2'
import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
import RoadsDropdown from '../Dropdowns/RoadsDropdown.vue'
import SectionsDropdown from '../Dropdowns/SectionsDropdown.vue'
import { customSortMixin } from '@/mixins/customSortMixin'

export default {
  name: 'condition-list',
  mixins: [customSortMixin],
  data() {
    return {
      selectedRegion: null,
      selectedRoad: null,
      selectedSection: null,
      condition: {},

      showSpinner: false,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [10, 20, 50, 100],
        total: 0
      },
      currentSort: 'start_distance',
      currentSortOrder: 'asc',
    }
  },
  components: {
    Pagination,
    RegionsDropdown,
    RoadsDropdown,
    SectionsDropdown
  },

  mounted() {
    this.showSpinner = true
    this.$store.dispatch('LOAD_HISTORY').then((history) => {
      if (history.for === 'condition' && history.use) {
        this.selectedRegion = history.data.selectedRegion
        this.selectedRoad = history.data.selectedRoad
        this.selectedSection = history.data.selectedSection
        this.currentSort = history.data.currentSort
        this.currentSortOrder = history.data.currentSortOrder
        this.pagination.perPage = history.data.perPage
        this.$nextTick(() => {
          this.searchQuery = history.data.searchQuery
          this.pagination.currentPage = history.data.currentPage
        })
        this.$store.dispatch('CLEAR_HISTORY')
      } else {
        this.selectedRegion = null
        this.selectedRoad = null
        this.selectedSection = null
        this.$store.commit('SET_CONDITION_LIST', [])
      }
      this.showSpinner = false
    })
  },

  methods: {
    reloadData() {
      if (!this.selectedSection) return

      this.showSpinner = true
      this.$store.dispatch('LOAD_CONDITION_LIST', this.selectedSection).then(() => {
        this.showSpinner = false
      })
    },
    save_history() {
      const hist = {
        selectedRegion: this.selectedRegion,
        selectedRoad: this.selectedRoad,
        selectedSection: this.selectedSection,
        searchQuery: this.searchQuery,
        currentSort: this.currentSort,
        currentSortOrder: this.currentSortOrder,
        perPage: this.pagination.perPage,
        currentPage: this.pagination.currentPage
      }
      this.$store.dispatch('SAVE_HISTORY', { data: hist, for: 'condition' })
    },
    editCondition(id) {
      this.save_history()
      this.$router.push(`/condition_data/condition/upd/${id}`)
    },
    addCondition() {
      this.save_history()
      this.$router.push(`/condition_data/condition/add`)
    },
    handleDelete(item) {
      const alert = {
        title: this.$t('modal.are_you_sure'),
        text: this.$t('modal.you_want_delete', { name: item.start_distance + '-' + item.end_distance }) + '\r\n' +
          this.$t('modal.the_operation_cannot_be_canceled'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'md-button md-success btn-fill',
        confirmButtonText: this.$t('modal.yes_delete'),
        cancelButtonClass: 'md-button md-danger btn-fill',
        cancelButtonText: this.$t('buttons.cancel'),
        buttonsStyling: false
      }
      this.save_history()
      Swal.fire(alert).then((result) => {
        if (result.value) {
          this.$store.dispatch('DEL_CONDITION', item.data_id).then(
            () => {
              this.reloadData()
              Swal.fire(this.$t('modal.deleted'), this.$t('modal.you_deleted', {
                name: item.start_distance + '-' + item.end_distance
              }), 'success')
            },
            (err) => {
              Swal.fire(this.$t('messages.error'), err, 'error')
            }
          )
        }
      })
    },
  },

  computed: {
    ...mapState({
      conditionList: (state) => state.Condition.list,
    }),
    ...mapGetters(['amIAdmin']),
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) highBound = this.total
      return highBound
    },
    total() {
      return this.conditionList.length
    },
    tableData() {
      return this.conditionList.slice(this.from, this.to)
    }
  },
  watch: {
  }
}
</script>
<style lang="scss" scoped>
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.md-table-head-label {
  padding-right: 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-card {
  margin: 0px 0;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button+.md-button {
  margin-left: 10px;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>
